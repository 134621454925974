<template>
  <div class="view">
    <div class="view_title">
      <div class="view_title_item view_title_item_left">
        <span>男科</span>
        <div class="view_title_text_bg"/>
      </div>

      <div class="view_title_item title_right">
        <div class="view_title_item_riht_show">
          <img src="../../assets/images/nan.png">
        </div>
        <div class="view_title_item_riht_bg">
          <img src="../../assets/images/zitu_bg_icon.png">
        </div>
      </div>
    </div>
    <div class="view_content_bg">
      <div class="view_content_bg_title">详情数据</div>

      <div class="view_content_bg_item" @click="openValue('睾丸酮')">
        <div class="view_content_bg_item_title">
          <span>睾丸酮</span><span>{{ andrology1Index }}分</span><span>/100</span><span>{{ andrology1 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="andrology1" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('勃起传导')">
        <div class="view_content_bg_item_title">
          <span>勃起传导</span><span>{{ andrology2Index }}分</span><span>/100</span><span>{{ andrology2 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="andrology2" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('前列腺增生')">
        <div class="view_content_bg_item_title">
          <span>前列腺增生</span><span>{{ andrology3Index }}分</span><span>/100</span><span>{{ andrology3 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="andrology3" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('前列腺钙化')">
        <div class="view_content_bg_item_title">
          <span>前列腺钙化</span><span>{{ andrology4Index }}分</span><span>/100</span><span>{{ andrology4 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="andrology4" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('前列腺炎症')">
        <div class="view_content_bg_item_title">
          <span>前列腺炎症</span><span>{{ andrology5Index }}分</span><span>/100</span><span>{{ andrology5 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="andrology5" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div style="height: 28px"/>

    </div>
    <view_bottom></view_bottom>
  </div>
</template>

<script>
import view_bottom from "@/pages/healthy/view_bottom";
import axios from "axios";

export default {
  name: "ReportNan",
  components: {view_bottom},
  data() {
    return {
      andrology1: "",
      andrology2: "",
      andrology3: "",
      andrology4: "",
      andrology5: "",
      andrology1Index: "",
      andrology2Index: "",
      andrology3Index: "",
      andrology4Index: "",
      andrology5Index: "",
    }
  },
  created() {
    // document.title = '舌诊评估'
    let userId = this.$route.query.userId
    console.log(userId)
    this.getcode(userId)
  },
  methods: {
    getcode(userId) {
      let url = "https://shezhen.hnxa168.com/api/app/getandrology?" + userId
      // let url = "https://shezhen.yuanjiwei.cn/api/app/getandrology?" + userId
      this.br = "<br/>"
      axios.post(url)
      axios.post(url)
          .then((result) => {
            console.log(result)
            this.andrology1 = result.data.result.andrology1
            this.andrology2 = result.data.result.andrology2
            this.andrology3 = result.data.result.andrology3
            this.andrology4 = result.data.result.andrology4
            this.andrology5 = result.data.result.andrology5
            this.andrology1Index = result.data.result.andrology1Index
            this.andrology2Index = result.data.result.andrology2Index
            this.andrology3Index = result.data.result.andrology3Index
            this.andrology4Index = result.data.result.andrology4Index
            this.andrology5Index = result.data.result.andrology5Index
            this.initBarChart("andrology1", this.andrology1)
            this.initBarChart("andrology2", this.andrology2)
            this.initBarChart("andrology3", this.andrology3)
            this.initBarChart("andrology4", this.andrology4)
            this.initBarChart("andrology5", this.andrology5)
          }).catch(error => {
        this.$router.replace("error")
      });
    },
    initBarChart(view, count) {
      let jiankang = this.$echarts.init(document.getElementById(view))
      let option = {
        grid: {
          left: '0',
          top: '0',
          right: '0',
          bottom: '0',
          containLabel: true,
          width: '100%'
        },
        xAxis: {
          type: 'value',
          splitLine: {show: false},
          axisLabel: {show: false},
          axisTick: {show: false},
          axisLine: {show: false},

        },
        yAxis: {
          type: 'category',
          axisTick: {show: false},
          axisLine: {show: false},
          axisLabel: {
            color: 'black',
            fontSize: 25,
            show: false,
          },
        },
        series: [
          {
            type: 'bar',
            barWidth: 18,
            data: [count],
            label: {
              //position    : 'middle',
              offset: [20, 2],
              color: '#fff',
              fontSize: 30,
            },
            itemStyle: {
              color: this.$echarts.graphic.LinearGradient(
                  1, 0, 0, 0,
                  [
                    {offset: 0, color: '#16CCBD'},                   //柱图渐变色
                    // {offset: 0.5, color: '#44C0C1'},                 //柱图渐变色
                    {offset: 1, color: '#4BEB76'},                   //柱图渐变色
                  ]
              ),
              barBorderRadius: 9,
            },
            zlevel: 1
          },
          {
            name: '进度条背景',
            type: 'bar',
            barGap: '-100%',
            barWidth: 18,
            data: [100],
            color: '#E3ECF2',//柱条颜色
            itemStyle: {
              normal: {
                barBorderRadius: 9,
                borderColor: '#FEFEFE',
              }
            }
          }
        ]
      };
      jiankang.setOption(option);
    },
    openValue(info){
      this.$router.push({path:"/Healthy/Introduce",query:{userId:info,date:new Date().getTime()}})
    }
  }
}
</script>

<style scoped>

.view_content_bg_item {
  background: #F7FAFA;
  border-radius: 32px;
  margin-top: 24px;
  margin-left: 32px;
  margin-right: 32px;
}

.view_content_bg_item_title {
  padding: 32px 32px 16px;
}

.view_content_bg_item_title > span:nth-child(1) {
  font-size: 28px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
}

.view_content_bg_item_title > span:nth-child(2) {
  font-size: 28px;
  font-weight: 400;
  color: rgba(255, 195, 0, 1);
  padding-left: 16px;
  text-align: left;
  vertical-align: top;
}

.view_content_bg_item_title > span:nth-child(3) {
  font-size: 24px;
  font-weight: 400;
  color: rgba(199, 199, 199, 1);
  text-align: center;
  margin-top: 4px;
  padding-left: 6px;
  vertical-align: top;
  float: right;
}

.view_content_bg_item_title > span:nth-child(4) {
  font-size: 28px;
  font-weight: 400;
  color: rgba(23, 203, 188, 1);
  text-align: right;
  vertical-align: top;
  float: right;
}

.view_content_bg_item_buttom {
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 40px;
}
</style>
