<template>
  <div class="content">
    <img src="../../assets/images/健康预警机器人ppt-1.jpg">
    <img src="../../assets/images/健康预警机器人ppt-2.jpg">
    <img src="../../assets/images/健康预警机器人ppt-4.jpg">
    <img src="../../assets/images/健康预警机器人ppt-5.jpg">
    <img src="../../assets/images/健康预警机器人ppt-6.jpg">
    <img src="../../assets/images/健康预警机器人ppt-7.jpg">
    <img src="../../assets/images/健康预警机器人ppt-8.jpg">
    <img src="../../assets/images/健康预警机器人ppt-9.jpg">
    <img src="../../assets/images/健康预警机器人ppt-13.jpg">
    <img src="../../assets/images/健康预警机器人ppt-14.jpg">
    <img src="../../assets/images/健康预警机器人ppt-15.jpg">
    <img src="../../assets/images/健康预警机器人ppt-16.jpg">
    <img src="../../assets/images/健康预警机器人ppt-17.jpg">
    <img src="../../assets/images/健康预警机器人ppt-18.jpg">
    <img src="../../assets/images/健康预警机器人ppt-19.jpg">
    <img src="../../assets/images/健康预警机器人ppt-20.jpg">
    <img src="../../assets/images/健康预警机器人ppt-21.jpg">
    <img src="../../assets/images/健康预警机器人ppt-22.jpg">
  </div>
</template>

<script>
export default {
  name: "jieshao"
}
</script>

<style scoped>
.content{
  margin: 3px;
  background: #ffffff;
}

.content img{
  width:auto;
  height:auto;
  max-width:100%;
  max-height:100%;
}
</style>
