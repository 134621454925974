<template>
  <div class="content">
    <h3>15903675013  同微信 </h3>
    <h3>15903675013  同微信 </h3>
  </div>
</template>

<script>
export default {
  name: "jieshao"
}
</script>

<style scoped>
.content{
  margin: 3px;
  background: #ffffff;
}

</style>
