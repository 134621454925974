<template>
  <van-empty description="二维码失效了" />
</template>

<script>
import preventBack from 'vue-prevent-browser-back';
export default {
  name: "error",
  mixins: [preventBack],
}
</script>

<style scoped>

</style>
