<template>
  <van-empty description="已提交，请在设备上继续下一步操作" />
</template>

<script>
export default {
  name: "success"
}
</script>

<style scoped>

</style>
