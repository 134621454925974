<template>
  <div class="view">
    <div class="view_title">
      <div class="view_title_item view_title_item_left">
        <span>维生素</span>
        <div class="view_title_text_bg"/>
      </div>

      <div class="view_title_item title_right">
        <div class="view_title_item_riht_show">
          <img src="../../assets/images/yao.png">
        </div>
        <div class="view_title_item_riht_bg">
          <img src="../../assets/images/zitu_bg_icon.png">
        </div>
      </div>
    </div>
    <div class="view_content_bg">
      <div class="view_content_bg_title">详情数据</div>

      <div class="view_content_bg_item"  @click="openValue('维生素A')">
        <div class="view_content_bg_item_title">
          <span>维生素A</span><span>{{ vitamin1Index }}分</span><span>/100</span><span>{{ vitamin1 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="vitamin1" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item"  @click="openValue('维生素B1')">
        <div class="view_content_bg_item_title">
          <span>维生素B1</span><span>{{ vitamin2Index }}分</span><span>/100</span><span>{{ vitamin2 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="vitamin2" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('维生素B2')">
        <div class="view_content_bg_item_title">
          <span>维生素B2</span><span>{{ vitamin3Index }}分</span><span>/100</span><span>{{ vitamin3 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="vitamin3" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item"  @click="openValue('维生素B3')">
        <div class="view_content_bg_item_title">
          <span>维生素B3</span><span>{{ vitamin4Index }}分</span><span>/100</span><span>{{ vitamin4 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="vitamin4" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('维生素B6')">
        <div class="view_content_bg_item_title">
          <span>维生素B6</span><span>{{ vitamin5Index }}分</span><span>/100</span><span>{{ vitamin5 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="vitamin5" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item"  @click="openValue('维生素B12')">
        <div class="view_content_bg_item_title">
          <span>维生素B12</span><span>{{ vitamin6Index }}分</span><span>/100</span><span>{{ vitamin6 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="vitamin6" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('维生素C')">
        <div class="view_content_bg_item_title">
          <span>维生素C</span><span>{{ vitamin7Index }}分</span><span>/100</span><span>{{ vitamin7 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="vitamin7" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('维生素D')">
        <div class="view_content_bg_item_title">
          <span>维生素D</span><span>{{ vitamin8Index }}分</span><span>/100</span><span>{{ vitamin8 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="vitamin8" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('维生素E')">
        <div class="view_content_bg_item_title">
          <span>维生素E</span><span>{{ vitamin9Index }}分</span><span>/100</span><span>{{ vitamin9 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="vitamin9" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('维生素K')">
        <div class="view_content_bg_item_title">
          <span>维生素K</span><span>{{ vitamin10Index }}分</span><span>/100</span><span>{{ vitamin10 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="vitamin10" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div style="height: 28px"/>

    </div>
    <view_bottom></view_bottom>
  </div>
</template>

<script>
import view_bottom from "@/pages/healthy/view_bottom";
import axios from "axios";

export default {
  name: "ReportK",
  components: {view_bottom},
  data() {
    return {
      vitamin1: "",
      vitamin2: "",
      vitamin3: "",
      vitamin4: "",
      vitamin5: "",
      vitamin6: "",
      vitamin7: "",
      vitamin8: "",
      vitamin9: "",
      vitamin10: "",
      vitamin1Index: "",
      vitamin2Index: "",
      vitamin3Index: "",
      vitamin4Index: "",
      vitamin5Index: "",
      vitamin6Index: "",
      vitamin7Index: "",
      vitamin8Index: "",
      vitamin9Index: "",
      vitamin10Index: "",

    }
  },
  created() {
    // document.title = '舌诊评估'
    let userId = this.$route.query.userId
    console.log(userId)
    this.getcode(userId)
  },
  methods: {
    getcode(userId) {
      let url = "https://shezhen.hnxa168.com/api/app/getvitamin?" + userId
      // let url = "https://shezhen.yuanjiwei.cn/api/app/getvitamin?" + userId
      this.br = "<br/>"
      axios.post(url)
      axios.post(url)
          .then((result) => {
            console.log(result)
            this.vitamin1 = result.data.result.vitamin1
            this.vitamin2 = result.data.result.vitamin2
            this.vitamin3 = result.data.result.vitamin3
            this.vitamin4 = result.data.result.vitamin4
            this.vitamin5 = result.data.result.vitamin5
            this.vitamin6 = result.data.result.vitamin6
            this.vitamin7 = result.data.result.vitamin7
            this.vitamin8 = result.data.result.vitamin8
            this.vitamin9 = result.data.result.vitamin9
            this.vitamin10 = result.data.result.vitamin10
            this.vitamin1Index = result.data.result.vitamin1Index
            this.vitamin2Index = result.data.result.vitamin2Index
            this.vitamin3Index = result.data.result.vitamin3Index
            this.vitamin4Index = result.data.result.vitamin4Index
            this.vitamin5Index = result.data.result.vitamin5Index
            this.vitamin6Index = result.data.result.vitamin6Index
            this.vitamin7Index = result.data.result.vitamin7Index
            this.vitamin8Index = result.data.result.vitamin8Index
            this.vitamin9Index = result.data.result.vitamin9Index
            this.vitamin10Index = result.data.result.vitamin10Index
            this.initBarChart("vitamin1", this.vitamin1)
            this.initBarChart("vitamin2", this.vitamin2)
            this.initBarChart("vitamin3", this.vitamin3)
            this.initBarChart("vitamin4", this.vitamin4)
            this.initBarChart("vitamin5", this.vitamin5)
            this.initBarChart("vitamin6", this.vitamin6)
            this.initBarChart("vitamin7", this.vitamin7)
            this.initBarChart("vitamin8", this.vitamin8)
            this.initBarChart("vitamin9", this.vitamin9)
            this.initBarChart("vitamin10", this.vitamin10)

          }).catch(error => {
        this.$router.replace("error")
      });
    },
    initBarChart(view, count) {
      let jiankang = this.$echarts.init(document.getElementById(view))
      let option = {
        grid: {
          left: '0',
          top: '0',
          right: '0',
          bottom: '0',
          containLabel: true,
          width: '100%'
        },
        xAxis: {
          type: 'value',
          splitLine: {show: false},
          axisLabel: {show: false},
          axisTick: {show: false},
          axisLine: {show: false},

        },
        yAxis: {
          type: 'category',
          axisTick: {show: false},
          axisLine: {show: false},
          axisLabel: {
            color: 'black',
            fontSize: 25,
            show: false,
          },
        },
        series: [
          {
            type: 'bar',
            barWidth: 18,
            data: [count],
            label: {
              //position    : 'middle',
              offset: [20, 2],
              color: '#fff',
              fontSize: 30,
            },
            itemStyle: {
              color: this.$echarts.graphic.LinearGradient(
                  1, 0, 0, 0,
                  [
                    {offset: 0, color: '#16CCBD'},                   //柱图渐变色
                    // {offset: 0.5, color: '#44C0C1'},                 //柱图渐变色
                    {offset: 1, color: '#4BEB76'},                   //柱图渐变色
                  ]
              ),
              barBorderRadius: 9,
            },
            zlevel: 1
          },
          {
            name: '进度条背景',
            type: 'bar',
            barGap: '-100%',
            barWidth: 18,
            data: [100],
            color: '#E3ECF2',//柱条颜色
            itemStyle: {
              normal: {
                barBorderRadius: 9,
                borderColor: '#FEFEFE',
              }
            }
          }
        ]
      };
      jiankang.setOption(option);
    },
    openValue(info){
      this.$router.push({path:"/Healthy/Introduce",query:{userId:info,date:new Date().getTime()}})
    }
  }
}
</script>

<style scoped>

.view_content_bg_item {
  background: #F7FAFA;
  border-radius: 32px;
  margin-top: 24px;
  margin-left: 32px;
  margin-right: 32px;
}

.view_content_bg_item_title {
  padding: 32px 32px 16px;
}

.view_content_bg_item_title > span:nth-child(1) {
  font-size: 28px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
}

.view_content_bg_item_title > span:nth-child(2) {
  font-size: 28px;
  font-weight: 400;
  color: rgba(255, 195, 0, 1);
  padding-left: 16px;
  text-align: left;
  vertical-align: top;
}

.view_content_bg_item_title > span:nth-child(3) {
  font-size: 24px;
  font-weight: 400;
  color: rgba(199, 199, 199, 1);
  text-align: center;
  margin-top: 4px;
  padding-left: 6px;
  vertical-align: top;
  float: right;
}

.view_content_bg_item_title > span:nth-child(4) {
  font-size: 28px;
  font-weight: 400;
  color: rgba(23, 203, 188, 1);
  text-align: right;
  vertical-align: top;
  float: right;
}

.view_content_bg_item_buttom {
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 40px;
}
</style>
