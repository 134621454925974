<template>
  <div class="view">
    <div class="view_title">
      <div class="view_title_item view_title_item_left">
        <span>健康服务</span>
        <div class="view_title_text_bg"/>
      </div>

      <div class="view_title_item title_right">
        <div class="view_title_item_riht_show">
          <img src="../../assets/images/jiankangfuwu.png">
        </div>
        <div class="view_title_item_riht_bg">
          <img src="../../assets/images/zitu_bg_icon.png">
        </div>
      </div>
    </div>

    <div class="view_content_bg">
      <van-tabs animated background="#00000000"
                color="rgba(23, 203, 188, 1)" line-height="3px"
                swipeable
                title-active-color="rgba(23, 203, 188, 1)"
                title-inactive-color="rgba(0, 0, 0, 1)">

        <van-tab title="养生保健">
          <div class="glossoscopyList">
            <div class="glossoscopytitle_one">
              <img alt="" class="glossoscopytex" src="../../assets/images/assessment9.png">
              <span class="glossoscopytitle">饮食调养</span>
            </div>
            <div v-for="(care,cares) in diet_rehabilitation" :key="cares">
              <div class="explain">说明</div>
              <span class="fz24">{{ care.message }}</span>
              <div v-for="(item,itemvalue) in care.items" :key="itemvalue">
                <div class="Recuperation">
                  <img :src="item.photo" alt="">
                  <span class="pl30 fz28">{{ item.name }}<br/>
                        <span class=" fz20" v-html="item.desc"/>
                        </span>
                </div>
              </div>
            </div>
          </div>

          <div class="glossoscopyList">
            <div class="glossoscopytitle_one">
              <img alt="" class="glossoscopytex" src="../../assets/images/assessment8.png">
              <span class="glossoscopytitle">运动保健</span>
            </div>
            <div v-for="(care,cares) in sports_health_care" :key="cares">
              <div class="explain">说明</div>
              <span class="fz24">{{ care.message }}</span>
              <div v-for="(item,itemvalue) in care.items" :key="itemvalue">
                <div class="Recuperation">
                  <img :src="item.photo" alt="">
                  <span class="pl30 fz28">{{ item.name }}<br/>
                        <span class=" fz20" v-html="item.desc"/>
                        </span>
                </div>
              </div>
            </div>
          </div>

          <div class="glossoscopyList">
            <div class="glossoscopytitle_one">
              <img alt="" class="glossoscopytex" src="../../assets/images/assessment15.png">
              <span class="glossoscopytitle">情志起居调养</span>
            </div>
            <div v-for="(care,cares) in recuperates" :key="cares">
              <div class="explain">说明</div>
              <span class="fz24">{{ care.message }}</span>
              <div v-for="(item,itemvalue) in care.items" :key="itemvalue">
                <div class="Recuperation">
                  <img :src="item.photo" alt="">
                  <span class="pl30 fz28">{{ item.name }}<br/>
                        <span class=" fz20" v-html="item.desc"/>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </van-tab>

        <van-tab title="中医建议">
                <div class="glossoscopyList">
                  <div class="glossoscopytitle_one">
                    <img alt="" class="glossoscopytex" src="../../assets/images/assessment11.png">
                    <span class="glossoscopytitle">艾灸按摩</span>
                  </div>

                  <div v-for="(care,cares) in massage_health_care" :key="cares">
                    <div class="explain">说明</div>
                    <span class="fz24">{{ care.message }}</span>
                    <div v-for="(item,itemvalue) in care.items" :key="itemvalue">
                      <div class="Recuperation">
                        <img :src="item.photo" alt="">
                        <span class="pl30 fz28">{{ item.name }}<br/>
                        <span class=" fz20" v-html="item.desc"/>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

          <div class="glossoscopyList">
            <div class="glossoscopytitle_one">
              <img alt="" class="glossoscopytex" src="../../assets/images/assessment14.png">
              <span class="glossoscopytitle">药物保健</span>
            </div>

            <div v-for="(care,cares) in drug_health_care" :key="cares">
              <div class="explain">说明</div>
              <span class="fz24">{{ care.message }}</span>
              <div v-for="(item,itemvalue) in care.items" :key="itemvalue">
                <div class="Recuperation">
                  <img :src="item.photo" alt="">
                  <span class="pl30 fz28">{{ item.name }}<br/>
                        <span class=" fz20" v-html="item.desc"/>
                  </span>
                </div>
              </div>
            </div>
          </div>

        </van-tab>
      </van-tabs>
    </div>
    <view_bottom></view_bottom>
  </div>
</template>

<script>
import view_bottom from "@/pages/healthy/view_bottom";
import axios from "axios";


export default {
  name: "HealthServices",
  components: {view_bottom},
  data() {
    return {
      bodyfluidNames: "",
      bodyfluidDescribe: "",
      colorOfMossNames: "",
      colorOfMossDescribe: "",
      colorOfTongueNames: "",
      colorOfTongueDescribe: "",
      mossNames: "",
      mossDescribe: "",
      shapeOfTongueNames: "",
      shapeOfTongueDescribe: "",
      veinNames: "",
      veinDescribe: "",
      constitutionNames: "",
      constitutionDescribe: "",
      singleConfigName: "",
      ossOriImgUrl: "",
      ossSplitImgUrl: "",
      ossSplitBackImgUrl: "",
      diagnostic_results: "",
      br: "",
      loading: false,
      finished: true,
      sports_health_care: [{
        message: "",
        items: [{
          name: "",
          photo: "",
          desc: ""
        }]
      }],
      diet_rehabilitation: [{
        message: "",
        items: [{
          name: "",
          photo: "",
          desc: ""
        }]
      }],
      predisposition: [{
        message: "",
        items: [{
          name: "",
          photo: "",
          desc: ""
        }]
      }],
      massage_health_care: [
        {
          message: "",
          items: [{
            name: "",
            photo: "",
            desc: ""
          }]
        }
      ],
      main_performance: [
        {
          message: "",
          items: [{
            name: "",
            photo: "",
            desc: ""
          }]
        }
      ],
      occur_reason: [
        {
          message: "",
          items: [{
            name: "",
            photo: "",
            desc: ""
          }]
        }
      ],
      drug_health_care: [
        {
          message: "",
          items: [{
            name: "",
            photo: "",
            desc: ""
          }]
        }
      ],
      recuperates: [
        {
          message: "",
          items: [{
            name: "",
            photo: "",
            desc: ""
          }]
        }
      ],
    };
  },
  created() {
    // document.title = '舌诊评估'
    let userId = this.$route.query.userId
    console.log(userId)
    this.getcode(userId)
  },
  methods: {
    getcode(userId) {
      let url = "https://shezhen.hnxa168.com/api/app/details?" + userId
      // let url = "https://shezhen.yuanjiwei.cn/api/app/details?" + userId
      this.br = "<br/>"
      axios.post(url)
          .then((result) => {
            console.log(result);
            let details = result.data.result.details
            this.bodyfluidNames = details.bodyfluidnames
            this.bodyfluidDescribe = details.bodyfluiddescribe
            this.colorOfMossNames = details.colorofmossnames
            this.colorOfMossDescribe = details.colorofmossdescribe
            this.colorOfTongueNames = details.coloroftonguenames
            this.colorOfTongueDescribe = details.coloroftonguedescribe
            this.colorOfTongueDescribe = details.coloroftonguedescribe
            this.mossNames = details.mossnames
            this.mossDescribe = details.mossdescribe
            this.shapeOfTongueNames = details.shapeoftonguenames
            this.shapeOfTongueDescribe = details.shapeoftonguedescribe
            this.shapeOfTongueDescribe = details.shapeoftonguedescribe
            this.veinNames = details.veinnames
            this.veinDescribe = details.veindescribe
            this.constitutionNames = details.constitutionnames
            this.constitutionDescribe = details.constitutiondescribe
            this.singleConfigName = details.singleconfigname
            this.ossOriImgUrl = details.ossoriimgurl
            this.ossSplitImgUrl = details.osssplitimgurl
            this.ossSplitBackImgUrl = details.osssplitbackimgurl
            let json = JSON.parse(details.treatplanjson)
            console.log(json);
            this.diagnostic_results = json[0].diagnostic_results
            this.sports_health_care = json[0].sports_health_care
            this.diet_rehabilitation = json[0].diet_rehabilitation
            this.predisposition = json[0].predisposition
            this.massage_health_care = json[0].massage_health_care
            this.main_performance = json[0].main_performance
            this.occur_reason = json[0].occur_reason
            this.drug_health_care = json[0].drug_health_care
            this.recuperates = json[0].recuperates
          }).catch(error => {
        this.$router.replace("error")
      });
    },
  },
};
</script>

<style scoped>
.pl30 {
  padding-left: 30px;
}

.fz28 {
  color: #000000;
  font-size: 30px;
}

.fz20 {
  font-size: 20px;
  color: #999999;
}

.p15 {
  padding: 10px;
}

.Recuperation {
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: rgba(245, 245, 245, 1);
  border-radius: 15px;
}

.Recuperation > img {
  width: 120px;
  height: 120px;
  display: block;
  border-radius: 25px;
}

.explain {
  font-size: 28px;
  color: rgba(56, 56, 56, 1);
  padding: 20px 0;
}

.glossoscopyimg > img {
  width: 160px;
  height: 160px;
  display: block;
  border-radius: 50%;
}

.col9 {
  color: #999999;
}

.df {
  display: flex;
}

.jub {
  justify-content: space-between;
}

.pt30 {
  padding-top: 30px;
}

.coltt {
  color: rgba(0, 186, 173, 1);
}

.al {
  align-items: center;
}

.fz25 {
  font-size: 25px;
}

.fz24 {
  font-size: 26px;
  color: #999999;
  line-height: 45px;
  padding-top: 30px;
}

.glossoscopytitle_two {
  font-size: 40px;
  color: rgba(0, 186, 173, 1);
  font-weight: 650;
  text-align: center;
  /* border-bottom: 6px solid rgba(0, 186, 173, 1); */
  /* width: 50px; */
  margin: 0 auto 30px auto;
  /* padding-bottom: 45px; */
}

.glossoscopytitle_one {
  display: flex;
  align-items: center;
}

.glossoscopytex {
  width: 30px;
  height: 30px;
  /* background-color: #000000; */
  /* border-radius: 50%; */
  display: block;
  margin-right: 10px;
}

.glossoscopyList {
  background-color: #ffffff;
  border-radius: 25px;
  padding: 30px;
  margin-bottom: 30px;
}

.glossoscopytitle {
  font-size: 26px;
  color: #000000;
  font-weight: 650;
}

.glossoscopy {
  padding: 0.4rem;
  background: rgba(0, 186, 173, 1);
}

/deep/ .van-nav-bar .van-icon {
  color: black !important;
}

.assessment > img {
  width: 160px;
  height: 160px;
  display: block;
  border-radius: 50%;
}

.assessment {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.commentsItem {
  display: flex;
  justify-content: space-between;
}

.diagnostic_results_view{
  text-align: center;
  margin-top: 80px;
  margin-bottom: 40px;
}

.diagnostic_results_view span{
  padding: 8px 24px;
  border-radius: 38px;
  background: rgba(217, 250, 248, 1);

  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 24px;
  color: rgba(0, 186, 173, 1);
  vertical-align: top;
}
</style>
